import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import "./create-house.view.scss";
import { useState } from "react";

export function CreateHouseView() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0.0);
  const [maxGuests, setMaxGuests] = useState(1);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactNotes, setContactNotes] = useState("");

  const [space, setSpace] = useState("");
  const [access, setAccess] = useState("");
  const [notes, setNotes] = useState("");

  const [bedRooms, setBedRooms] = useState("");
  const [beds, setBeds] = useState("");
  const [bathrooms, setBathrooms] = useState("");

  const [address, setAddress] = useState("");
  const [googleMaps, setGoogleMaps] = useState("");

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const [duringStay, setDuringStay] = useState([""]);
  const [beforeLeaving, setBeforeLeaving] = useState([""]);

  // const [images, setImages] = useState("");

  function createHouse() {
    let res = {
      id: 1,
      title: title,
      description: description,
      price: price,
      maxGuests: maxGuests,

      contact: {
        name: name,
        email: email,
        phone: phone,
        obs: contactNotes,
      },

      images: [],

      about: {
        space: space,
        access: access,
        notes: notes,
      },

      count: {
        bedrooms: bedRooms,
        beds: beds,
        bathrooms: bathrooms,
      },

      location: {
        address: address,
        googleMaps: googleMaps,
      },

      checkInOut: {
        checkIn: checkIn,
        checkOut: checkOut,
      },

      rules: {
        during: {
          list: duringStay,
          forbidden: [],
        },
        beforeLeaving: {
          list: beforeLeaving,
        },
      },

      cancellationPolicy: {
        list: [],
      },
    };

    var dictstring = JSON.stringify(res);
    const file = new File([dictstring], "house.json", {
      type: "application/json",
    });

    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  return (
    <Container className="create-house-wrapper pt-5">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          createHouse();
        }}
      >
        <div className="d-flex justify-content-between">
          <h3>Create house object</h3>
          <Button type="submit">Gerar objeto</Button>
        </div>

        {/* Anúncio */}
        <div className="house-form-section">
          <h4 className="mt-5">Anúncio</h4>
          <FloatingLabel label="Título" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Descrição" className="mb-3">
            <Form.Control
              required
              as={"textarea"}
              placeholder="Descrição"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FloatingLabel>

          <Row>
            <Col>
              <FloatingLabel label="Preço" className="mb-3">
                <Form.Control
                  required
                  type="number"
                  placeholder="Preço"
                  value={price}
                  onChange={(e) => setPrice(Number(e.target.value))}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="Número máximo de hóspedes" className="mb-3">
                <Form.Control
                  required
                  type="number"
                  placeholder="Número máximo de hóspedes"
                  value={maxGuests}
                  onChange={(e) => setMaxGuests(Number(e.target.value))}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </div>

        {/* Contato */}
        <div className="house-form-section">
          <h4 className="mt-5">Contato</h4>

          <FloatingLabel label="Nome" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Email" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Telefone" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Observações" className="mb-3">
            <Form.Control
              required
              as="textarea"
              placeholder="Observações"
              value={contactNotes}
              onChange={(e) => setContactNotes(e.target.value)}
            />
          </FloatingLabel>
        </div>

        {/* Sobre a casa */}
        <div className="house-form-section">
          <h4 className="mt-5">Sobre a casa</h4>
          <FloatingLabel label="Espaço" className="mb-3">
            <Form.Control
              required
              as="textarea"
              placeholder="Espaço"
              value={space}
              onChange={(e) => setSpace(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Acesso" className="mb-3">
            <Form.Control
              required
              as="textarea"
              placeholder="Acesso"
              value={access}
              onChange={(e) => setAccess(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Observações" className="mb-3">
            <Form.Control
              required
              as="textarea"
              placeholder="Observações"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FloatingLabel>
        </div>

        {/* Contador */}
        <div className="house-form-section">
          <h4 className="mt-5">Contador</h4>

          <FloatingLabel label="Quartos" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Número de quartos"
              value={bedRooms}
              onChange={(e) => setBedRooms(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Camas" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Número de camas"
              value={beds}
              onChange={(e) => setBeds(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Banheiros" className="mb-3">
            <Form.Control
              required
              type="number"
              placeholder="Número de banheiros"
              value={bathrooms}
              onChange={(e) => setBathrooms(e.target.value)}
            />
          </FloatingLabel>
        </div>

        {/* Localização */}
        <div className="house-form-section">
          <h4 className="mt-5">Localização</h4>

          <FloatingLabel label="Endereço" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Endereço"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="URL do Google Maps" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="URL do Google Maps"
              value={googleMaps}
              onChange={(e) => setGoogleMaps(e.target.value)}
            />
          </FloatingLabel>
        </div>

        {/* Check In & Out */}
        <div className="house-form-section">
          <h4 className="mt-5">Check-in & Check-out</h4>
          <FloatingLabel label="Check-in" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Check-in"
              value={checkIn}
              onChange={(e) => setCheckIn(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel label="Check-out" className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Check-out"
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
            />
          </FloatingLabel>
        </div>

        {/* Regras | Durante a estadia*/}
        <div className="house-form-section">
          <div className="mt-5 d-flex align-items-center justify-content-between">
            <h4>Regras | Durante a estadia</h4>
            <Button
              style={{ height: "fit-content" }}
              onClick={() => {
                setDuringStay([...duringStay, ""]);
              }}
            >
              +
            </Button>
          </div>
          <div className="mb-3"></div>
          {duringStay.map((rule, index) => (
            <InputGroup className="mb-3">
              <FloatingLabel label="Regra">
                <Form.Control
                  required
                  type="text"
                  placeholder="Regra"
                  value={rule}
                  onChange={(e) => {
                    const newRules = [...duringStay];
                    newRules[index] = e.target.value;
                    setDuringStay(newRules);
                  }}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <span
                  className="material-symbols-outlined text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const newRules = [...duringStay];
                    newRules.splice(index, 1);
                    setDuringStay(newRules);
                  }}
                >
                  delete
                </span>
              </InputGroup.Text>
            </InputGroup>
          ))}
        </div>

        {/* Regras | Antes de sair*/}
        <div className="house-form-section">
          <div className="mt-5 d-flex align-items-center justify-content-between">
            <h4>Regras | Antes de sair</h4>
            <Button
              style={{ height: "fit-content" }}
              onClick={() => {
                setBeforeLeaving([...beforeLeaving, ""]);
              }}
            >
              +
            </Button>
          </div>
          <div className="mb-3"></div>
          {beforeLeaving.map((rule, index) => (
            <InputGroup className="mb-3">
              <FloatingLabel label="Regra">
                <Form.Control
                  required
                  type="text"
                  placeholder="Regra"
                  value={rule}
                  onChange={(e) => {
                    const newRules = [...beforeLeaving];
                    newRules[index] = e.target.value;
                    setBeforeLeaving(newRules);
                  }}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <span
                  className="material-symbols-outlined text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const newRules = [...beforeLeaving];
                    newRules.splice(index, 1);
                    setBeforeLeaving(newRules);
                  }}
                >
                  delete
                </span>
              </InputGroup.Text>
            </InputGroup>
          ))}
        </div>
      </Form>
    </Container>
  );
}
