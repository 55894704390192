import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeView } from "./views/home/home.view";
import { CreateHouseView } from "./views/create_house/create-house.view";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<HomeView />} />
        <Route path="/create-house" element={<CreateHouseView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
